.custom-control-label {
  margin-bottom: 0 !important;
  padding-top: 3px;
}

.bg-primary {
  background-color: #e23d55 !important;
}
.bg-2 {
  background-color: #456990 !important;
}
.bg-3 {
  background-color: #49beaa !important;
}
.bg-4 {
  background-color: #2335d3 !important;
}
.bg-5 {
  background-color: #eeb868 !important;
}
.bg-6 {
  background-color: #351431 !important;
}
.bg-7 {
  background-color: #d4adcf !important;
}
.bg-8 {
  background-color: #b6009b !important;
}
.bg-9 {
  background-color: #000000 !important;
}
